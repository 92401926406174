.scrollbar[data-orientation="vertical"] {
    width: 12px;
    border-left: 1px solid var(--hv-border-default);
    border-right: 1px solid var(--hv-border-default);
  }
  .scrollbar[data-orientation="horizontal"] {
    height: 12px;
    border-top: 1px solid var(--hv-border-default);
    border-bottom: 1px solid var(--hv-border-default);
  }
  .scrollbar[data-orientation="vertical"] .thumb {
    background-color: var(--hv-scroll-default);
    width: 6px;
  }
  .scrollbar[data-orientation="horizontal"] .thumb {
    background-color: var(--hv-scroll-default);
    height: 6px;
  }

.corner {
  opacity: 1;
  background: transparent;
  border-right: 1px solid var(--hv-border-default);
  border-bottom: 1px solid var(--hv-border-default);
}
