.buttonSection {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  gap: var(--hv-spacing-2);
  margin-top: var(--hv-spacing-3);
}

.buttonSection button {
  width: 80px;
  height: 32px;
}

.title {
  font-size: var(--hv-fontsize-body-md);
  line-height: var(--hv-lineheight-body-md);
  font-weight: var(--hv-font-weight-semibold);
  color: var(--hv-text-normal-primary);
}
.content {
  border-radius: 16px;
  padding: 0;
  color: var(--hv-text-normal-secondary);
  font-size: var(--hv-fontsize-body-sm);
  line-height: var(--hv-lineheight-body-sm);
  box-shadow: var(--hv-shadow-3);
  background-color: var(--hv-surface-popup);
}
.header {
  padding: 22px 24px 0 24px;
  background-color: var(--hv-surface-popup);
}
.body {
  padding: 12px 24px 20px 24px;
}
.close {
  width: 24px;
  height: 24px;
}
