.time-range {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0;
  border-top: 1px solid var(--hv-border-default);
}

.time-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time-group:first-child {
  margin-bottom: 16px;
}

.time-label-date {
  display: flex;
  align-items: center;
  line-height: 18px;
  gap: 8px;
}

.time-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--hv-text-normal-primary);
}

.time-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--hv-text-normal-tertiary);
}

.time-input-group {
  display: flex;
  align-items: center;
  gap: 4px;
}

.time-input-group input {
  background-color: var(--hv-input-foreground-default);
  border: 1px solid var(--hv-input-outline-default);
}

.time-input-group input:disabled {
  color: var(--hv-text-normal-disabled);
  background-color: var(--hv-input-foreground-disabled);
  border: 1px solid var(--hv-input-outline-default);

  pointer-events: none;
  cursor: not-allowed;
}

.time-input-group input:disabled:hover,
.time-input-group input:disabled:focus {
  background-color: initial;
}

.time-input-group button svg path {
  stroke: var(--hv-icon-normal-disabled);
  stroke-opacity: 1;
}

.time-input-group button:disabled {
  color: var(--hv-text-normal-disabled);
  pointer-events: none;
  cursor: not-allowed;
}

.time-input-group button:disabled svg path {
  stroke: var(--hv-icon-normal-disabled);
  stroke-opacity: 1;
}

.time-input-group button:disabled:hover,
.time-input-group button:disabled:focus {
  background-color: initial;
}

.time-input-group span {
  color: var(--hv-text-normal-secondary);
}

.input-arrow-container {
  position: relative;
  display: flex;
  align-items: center;
}

.arrow-container {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.arrow-container button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-container button svg path {
  fill: var(--hv-icon-normal-secondary);
}

input[type="number"] {
  width: 60px;
  height: 32px;
  padding: 7px 8px;
  font-size: 14px;
  border-radius: 4px;
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-input-foreground-default);
  border: 1px solid var(--hv-input-outline-default);
  outline: none;
  box-sizing: border-box;
}

input[type="number"]:hover {
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-input-foreground-hover);
  border: 1px solid var(--hv-input-outline-hover);
}

input[type="number"]:active {
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-input-foreground-press);
  border: 1px solid var(--hv-input-outline-press);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-border {
  border: 1px solid var(--hv-data-red) !important;
}

.date-range-calendar {
  display: flex;
  flex-direction: column;
}

.button-submit {
  margin-left: auto;
  padding: 20px;
}

.button-submit button {
  min-width: 80px;
}

.toggle-button {
  width: 46px;
  font-size: 14px;
  border-radius: 0;
  color: var(--hv-text-normal-tertiary);
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
}

.toggle-button:hover {
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-button-secondary-hover);
}

.toggle-button.active {
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-button-secondary-press);
}

.toggle-button:first-child {
  border-top-left-radius: 1px !important;
  border-bottom-left-radius: 1px !important;
}

.toggle-button:last-child {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.am-pm-toggle {
  width: 92px;
  display: flex;
  height: 32px;
  border: 1px solid var(--hv-button-tertiary-outline-default) !important;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 4px;
}

.am-pm-toggle button:focus-visible {
  outline: 2px solid;
}

.am-pm-toggle .toggle-button.active:disabled {
  background-color: transparent !important;
}

.reactDatepicker {
  width: 344px;
  border: 0;
  border-radius: 16px;
  box-shadow: none;
}

.reactDatepicker .reactDatepicker__month {
  padding: 0 16px 20px;
}

.reactDatepicker .reactDatepicker__dayNames {
  margin: 16px 0 12px;
}

.reactDatepicker > .reactDatepicker__monthContainer {
  width: 100%;
  height: 324px;
}

.reactDatepicker
  > .reactDatepicker__monthContainer
  + .reactDatepicker__monthContainer {
  border-right: 0;
  padding-right: 0;
}

.reactDatepicker__day:not([aria-disabled="true"]):hover {
  background-color: transparent;
}
/* stylelint-disable selector-class-pattern */
