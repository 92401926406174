/* Default Track */
.track {
  height: 20px;
  width: 34px;
  background: var(--hv-toggle-default);
  border-radius: 999px;
}

/* outlineLarge | filledLarge Track */
.root[data-variant="filledLarge"] .track,
.root[data-variant="outlineLarge"] .track {
  height: 24px;
  width: 54px;
}

.root[data-variant="filledLarge"] .trackLabel {
  color: var(--hv-text-on-brand-primary);
}

.root[data-variant="outlineLarge"] .input:checked ~ .track .trackLabel {
  color: var(--switch-color);
}

/* Default checked Track Track */
.root .input:checked + .track {
  border-color: transparent;
  background-color: var(--switch-color);
}

/* outline | outlineLarge Track */
.root[data-variant="outline"] .track,
.root[data-variant="outlineLarge"] .track {
  background: transparent;
  border-color: var(--hv-toggle-default);
}

/* outline | outlineLarge checked Track Track */
.root[data-variant="outline"] .input:checked + .track,
.root[data-variant="outlineLarge"] .input:checked + .track {
  border-color: var(--switch-color);
  background-color: transparent;
}

/* Default thumb */
.thumb {
  width: 16px;
  height: 16px;
  background: var(--hv-toggle-handle);
}

/* outline thumb */
.root[data-variant="outline"] .thumb {
  width: 12px;
  height: 12px;
  background: var(--hv-toggle-default);
  border: none;
}

/* outlineLarge thumb */
.root[data-variant="outlineLarge"] .thumb {
  width: 14px;
  height: 14px;
  border: none;
  background: var(--hv-toggle-default);
}

/* outline | outlineLarge checked thumb */
.root[data-variant="outline"] .input:checked ~ .track .thumb,
.root[data-variant="outlineLarge"] .input:checked ~ .track .thumb {
  background: var(--switch-color);
}

.root .input:disabled ~ .track {
  opacity: 0.4;
}
