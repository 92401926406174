.container {
  flex-direction: row;
  align-items: center;
  min-width: 260px;
  height: 28px;
  padding-left: var(--mantine-spacing-3);
  padding-right: var(--mantine-spacing-2);
  gap: var(--mantine-spacing-1);
  color: var(--hv-text-normal-secondary);
}
  .container:hover {
    background-color: var(--hv-interaction-hover);
    color: var(--hv-text-normal-primary);
    cursor: pointer;
  }
  .container:active {
    background-color: var(--hv-interaction-press);
    color: var(--hv-text-normal-primary);
  }
  .container:focus {
    outline: 2px solid var(--hv-interaction-select-orange);
    border-radius: 4px;
  }
.container:where([data-type="item"]) {
  padding-left: var(--mantine-spacing-9);
}
.container:where([data-type="item"]):where([data-selected = true]) {
  background-color: var(--hv-interaction-press);
  position: relative;
}
.container:where([data-type="item"]):where([data-selected = true])::before {
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    content: "";
    background-color: var(--hv-brand-primary);
  }
.caretContainer {
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
.caretContainer:hover {
    cursor: pointer;
  }
.checkBoxContainer {
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.labelContainer {
  flex: 1;
  gap: var(--mantine-spacing-1);
  flex-direction: row;
  align-items: center;
}
