.react-datepicker {
  font-family: inherit;
  width: 344px;
  border: 0;
  border-radius: 16px;
  box-shadow: none;
  background-color: var(--hv-surface-popup);
}

.react-datepicker__month-container {
  width: 100%;
  height: 324px;
}

.react-datepicker__header {
  padding: 0;
  border: 0;
  background-color: inherit;
}

.react-datepicker__month-container + .react-datepicker__month-container {
  border-right: 0;
  padding-right: 0;
}

.react-datepicker__day:not([aria-disabled="true"]):hover {
  background-color: transparent;
}

.react-datepicker__day[aria-disabled="true"] *:hover {
  background-color: transparent;
  color: var(--hv-text-on-brand-disabled);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  padding: 0;
  border: 0;
  background-color: inherit;
}

.react-datepicker__month {
  margin: 0;
  min-height: 208px;
}

.react-datepicker__day-names {
  margin: 16px 12px 12px 0px;
}

.react-datepicker__day-name {
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: var(--hv-text-normal-secondary);
  width: 44px;
}

.react-datepicker__day-name:first-child {
  color: var(--hv-data-red);
}

.date-picker-custom-day-name {
  width: 28px;
}

.react-datepicker__week {
  display: flex;
  justify-content: center;
}

.react-datepicker__week:not(:first-child) {
  margin-top: 8px;
}

.react-datepicker__day {
  color: var(--hv-text-normal-primary);
  background-color: transparent;
  width: 44px;
  margin: 0;
  font-size: 14px;
}

.react-datepicker__day:last-of-type {
  width: 28px;
}

.date-picker-custom-date {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 6px;
}

.react-datepicker__day:hover {
  background-color: transparent;
}

.react-datepicker__day--in-range {
  border-radius: 0;
}

.react-datepicker__day--range-end,
.react-datepicker__day--weekend[aria-label*="Saturday"] {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-datepicker__day:active {
  background-color: transparent;
}

.react-datepicker__day--today {
  background-color: transparent !important;
}

.date-picker-custom-today {
  font-weight: normal;
}

.react-datepicker__day--weekend[aria-label*="Sunday"] {
  color: var(--hv-data-red);
}

.react-datepicker__day--weekend[aria-label*="Sunday"][aria-disabled="false"]:not(.react-datepicker__day--range-start) > *:hover {
  color: var(--hv-data-red);
}

.react-datepicker__day--outside-month {
  color: var(--hv-text-on-brand-disabled);
}

.react-datepicker__day--outside-month.react-datepicker__day--weekend[aria-label*="Sunday"] {
  color: var(--hv-text-on-brand-disabled) !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  border-radius: 0;
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-interaction-hover) !important;
}

.react-datepicker__day--in-range > *:hover {
  background-color: var(--hv-button-primary-hover);
  color: var(--hv-text-on-brand-primary)
}

.react-datepicker__day--in-range .date-picker-start-of-month {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.react-datepicker__day--in-range .date-picker-end-of-month {
  width: 28px;
  margin-right: 16px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  background-color: var(--hv-button-primary-default) !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--weekend[aria-label*="Saturday"] {
  width: 28px;
  margin-right: 16px;
  border-radius: 6px;
}

.react-datepicker__day--selecting-range-end > *:hover {
  color: var(--hv-text-on-brand-primary) !important;
}

.react-datepicker__day--weekend[aria-label*="Sunday"] .date-picker-custom-date {
  border-radius: 6px;
}

.react-datepicker__day--range-end:last-of-type,
.react-datepicker__day--selecting-range-end:last-of-type {
  margin-right: 16px;
}

.date-picker-custom-today.date-picker-custom-date {
  border-radius: 6px;
}

.date-picker-custom-date {
  border-radius: 6px;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--weekend[aria-label*="Sunday"] {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  background-color: transparent;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end
  .date-picker-custom-date,
.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end
  .date-picker-custom-date {
  border-radius: 6px;
}

.react-datepicker__day--selected .date-picker-custom-date {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--orange-primary) !important;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
  color: var(--hv-text-on-brand-disabled) !important;
}

.react-datepicker__day > .date-picker-custom-date {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 6px;
}

.react-datepicker__day.react-datepicker__day--today .date-picker-custom-date {
  outline: 1px solid var(--mono-0);
  border: 1px solid var(--background-date-default);

  border-radius: 6px;
}

/* ------ */

.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end
  > div {
  border: 1px solid var(--hv-icon-normal-primary);
  border-radius: 6px;
}

.react-datepicker__day--in-range.react-datepicker__day--range-end > div {
  background-color: var(--hv-button-primary-default) !important;
  color: var(--hv-text-on-brand-primary);
}

.react-datepicker__day.react-datepicker__day--today > div {
  border: 1px solid var(--hv-icon-normal-primary);
  border-radius: 6px;
  font-weight: normal;
}

.react-datepicker__day--selected > div {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--hv-button-primary-default) !important;
  color: var(--hv-text-on-brand-primary);
}

.react-datepicker__day--selected > *:hover {
  color: var(--hv-text-on-brand-primary) !important;
}