.input {
  outline: 1px solid var(--hv-icon-normal-secondary);
  border-color: transparent;
  border-radius: 4px;
  background-color: transparent;
}
.input:hover {
  background: var(--hv-interaction-hover);
  outline-color: var(--hv-icon-normal-primary);
}
.input:disabled {
  background: var(--hv-input-foreground-disabled);
  outline-color: var(--hv-icon-normal-disabled);
}
.input:checked,
.input[data-indeterminate] {
  outline: none;
  border: none;
  background-color: var(--hv-button-primary-default);
}

.input[data-indeterminate]:hover {
  background-color: var(--hv-button-primary-hover);
}

.input[data-indeterminate]:disabled {
  background-color: var(--hv-button-primary-hover);
}

.icon {
  color: var(--hv-surface) !important;
}
