.tabs[data-variant="default"] {
  --tabs-list-border-width: 0px;
}
.tabList {
  gap: 32px !important;
}
.tab {
  background-color: transparent !important;
  border: none;
  min-width: 80px;
  height: 48px;
  padding: 0;
}

.tab:disabled {
  opacity: 1;
}

.tab:where([data-active]) {
  border-bottom: 2px solid var(--hv-interaction-select-orange);
}
.tab:where([data-active]):where([data-disabled]) {
  border-bottom: 2px solid var(--hv-icon-normal-disabled);
}
.tabText {
  font-weight: 500 !important;
  color: var(--hv-text-normal-secondary) !important;
}

.tabText:hover {
  font-weight: 600 !important;
  color: var(--hv-text-normal-primary) !important;
}

.tabText:disabled {
  font-weight: 500 !important;
  color: var(--hv-text-normal-disabled) !important;
}

.tab:where([data-disabled]) .tabText {
  font-weight: 500 !important;
  color: var(--hv-text-normal-disabled) !important;
}
.tab:where([data-active]) .tabText {
  font-weight: 600 !important;
  color: var(--hv-text-normal-primary) !important;
}
.tab:where([data-active]):where([data-disabled]) .tabText {
  font-weight: 500 !important;
  color: var(--hv-text-normal-disabled) !important;
}
.tab:where([data-active]):where([data-disabled]) .tabText {
  font-weight: 600 !important;
}
