.outerBox {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid transparent;
  text-decoration: none;
}
.outerBox:hover {
  background: var(--hv-interaction-hover);
  border-radius: var(--mantine-radius-sm);
}
.outerBox:active {
  background: var(--hv-interaction-press);
  border-radius: var(--mantine-radius-sm);
}
.outerBox:visited {
  text-decoration: unset !important;
  border: unset !important;
}
