.tooltip {
  background: var(--hv-tooltip-background);
  border-radius: 8px;
  border: 1px solid var(--hv-border-default);
  padding: var(--mantine-spacing-2) var(--mantine-spacing-3);
  min-height: 28px;
  max-width: 300px;
}
.box {
  line-height: 0;
}
.boxHover:hover {
  border-radius: 999px;
  background: var(--hv-interaction-hover);
}
.svg {
  width: 20px;
  stroke: var(--hv-icon-normal-secondary);
}
.svg:hover {
  stroke: white;
  fill: var(--hv-icon-normal-secondary);
}
