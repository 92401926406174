.bodyWrapper {
  margin-top: 21px;
  gap: 8px;
}
.unStyledButton {
  width: 300px;
  min-height: 52px;
  border-radius: 4px;
  background: var(--hv-surface);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.unStyledButton:hover {
    background-color: var(--hv-button-primary-hover);
  }
.unStyledButtonActive {
  background: var(--hv-button-primary-press);
}
.colorBar {
  height: 36px;
  width: 4px;
  border-radius: 4px;
  margin-left: 8px;
}
.labelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 0 12px;
  align-items: center;
}
