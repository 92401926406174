.header {
  font-size: 16px;
  /* color: var(--cal-text-color); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.icon-arrow svg path {
  stroke: var(--hv-icon-normal-secondary);
  stroke-opacity: 1;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 16px 16px 8px 12px;
}

.month-year-menu {
  display: flex;
  align-items: center;
  height: 32px;
}

.context-menu {
  height: auto;
}

.control ul {
  right: unset !important;
  min-width: 80px !important;
  padding: 0 !important;
  margin-top: 4px !important;
  background-color: var(--hv-surface-popup) !important;
  border: 1px solid var(--hv-border-default);
  box-shadow: var(--hv-shadow-2);
  border-radius: 4px;
  max-height: 240px;
  overflow-y: auto;
}

.control ul li button {
  width: 100%;
  background-color: var(--hv-surface-popup);
  color: var(--hv-text-normal-secondary);
}

.control ul li button:hover,
.control ul li button:focus,
.control ul li button:active {
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-interaction-hover);
}

.month-year {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0px 8px 0px 12px;
  width: 80px;
  border-radius: 4px;
  height: 32px;
  color: var(--hv-text-normal-secondary);
}

.month-year:hover .icon-dropdown {
  background-color: transparent;
}

.month-year:active .icon-dropdown {
  background-color: transparent;
}

.month-year:has(+ ul[data-visibility="visible"]) .icon-dropdown svg {
  transform: rotate(180deg);
}

.month-year:has(+ ul[data-visibility="visible"]) .icon-dropdown svg path {
  fill: var(--hv-icon-normal-secondary);
}

.icon-dropdown {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  border-radius: 2px;
  background-color: var(--hv-surface-popup);
}

.icon-dropdown svg {
  width: 16px;
  height: 16px;
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
}

.icon-dropdown svg path {
  fill: var(--hv-icon-normal-secondary);
  stroke: transparent;
}

.month-control {
  display: flex;
}

.month-control > span:last-child {
  margin-left: 20px;
}
