.root {
  color: var(--mantine-color-mono-1);
}
  [data-mantine-color-scheme='dark'] .root {
  color: white;
}
  .root {
  font-size: var(--mantine-font-size-sm);
  line-height: var(--mantine-line-height-sm);
  height: 32px;
  padding: 8px 0px 8px 16px;
  border-radius: var(--mantine-radius-default);
  display: flex;
}
.root:hover {
  color: var(--mantine-color-primary);
  font-weight: 600;
  text-decoration: none;
  background-color: var(--hv-orange-secondary);
}
[data-mantine-color-scheme='dark'] .root:hover {
  background-color: var(--hv-orange-tertiary);
}

.root:where([data-active]) {
  background-color: var(--hv-orange-secondary);
}

[data-mantine-color-scheme='dark'] .root:where([data-active]) {
  background-color: var(--hv-orange-tertiary);
}

.root:where([data-active]) {
  font-weight: 600;
  color: var(--mantine-color-primary);
}
