.calendar {
  /* stylelint-disable selector-class-pattern */
  background-color: var(--hv-surface-popup);
  border-radius: 16px 16px 0px 0px;
}

.calendar > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.date-picker-custom-date > span.shadow-date {
  display: none;
}

.date-picker-custom-date {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.date-picker-custom-date:hover {
  background-color: var(--hv-interaction-hover);
  color: var(--hv-text-normal-primary);
}
