.radio {
  border-color: var(--hv-icon-normal-secondary) !important;
  border-width: 1px !important;
  background-color: transparent !important;
  font-weight: 400;
  font-size: var(--mantine-font-size-smBody);
  line-height: var(--mantine-line-height-smBody);
}

.radio:hover {
  border-color: var(--hv-icon-normal-primary) !important;
  background-color: var(--hv-interaction-hover) !important;
}

.icon {
  --radio-icon-color: var(--hv-button-primary-default) !important;
  height: 37.5% !important;
  width: 37.5% !important;
  top: 31.25% !important;
  left: 31.25% !important;
}

.label {
  padding-inline-start: 4px;
  color: var(--hv-text-normal-primary) !important;
}

.label[data-disabled] {
  color: var(--hv-text-normal-disabled) !important;
}

.radio:checked {
  border-color: var(--hv-button-primary-default) !important;
  border-width: 2px !important;
}

.radio:checked:hover {
    border-color: var(--hv-button-primary-hover) !important;
  }

.radio:checked:hover + .icon {
    --radio-icon-color: var(--hv-button-primary-hover) !important;
  }

.radio:disabled {
  border-color: var(--hv-icon-normal-disabled) !important;
  background-color: var(--hv-input-foreground-disabled) !important;
}

.radio:disabled:hover {
    border-color: var(--hv-icon-normal-disabled) !important;
    background-color: var(--hv-input-foreground-disabled) !important;
  }

.radio:disabled:hover + .icon {
    --radio-icon-color: var(--hv-icon-normal-disabled) !important;
  }

.radio:disabled + .icon {
  --radio-icon-color: var(--hv-icon-normal-disabled) !important;
}
