.control {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: var(--hv-text-normal-secondary);
  background-color: transparent;
  height: 24px;
  min-width: 24px;
  font-size: 12px;
  line-height: 20px;
  border-radius: var(--hv-radius-xs);
}

.control:hover {
  background-color: var(--hv-interaction-hover);
  color: var(--hv-text-normal-primary);
}

.control:active {
  background-color: var(--hv-interaction-press);
  color: var(--hv-text-normal-primary);
}

.control:where([data-active]) {
  background-color: var(--hv-button-primary-default);
  color: var(--hv-text-on-brand-primary);
}

.control:where([data-active]):hover {
  background-color: var(--hv-button-primary-hover);
  color: var(--hv-text-on-brand-primary);
}

.control:where([data-active]):active {
  background-color: var(--hv-button-primary-press);
  color: var(--hv-text-on-brand-primary);
}

.dots {
  height: var(--pagination-control-size);
  min-width: var(--pagination-control-size);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
