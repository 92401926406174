.context-menu {
  font-size: 14px;
  position: relative;
  z-index: 50;
}

.context-menu > ul {
  transition: visibility 0.3s ease-in-out;
}

.context-menu ul {
  position: absolute;
  z-index: 5;
}

.context-menu ul ul {
  left: 100px;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
  top: 0;
  max-width: 200px;
  width: max-content;
}

.context-menu .has-icon {
  display: flex;
  align-items: center;
}

.context-menu .has-icon svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.context-menu > ul > li button {
  border-radius: 2px;
  color: var(--hv-text-normal-primary);

  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 5px 6px;
  cursor: pointer;
  align-items: center;
  position: relative;
  line-height: 18px;
}

.context-menu > ul > li li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.context-menu .icon {
  position: absolute;
  right: 6px;
}

.context-menu > ul > li:hover ul,
.context-menu > ul > li:hover li {
  visibility: visible;
}

.context-menu > ul > li > div {
  width: 24px;
  display: flex;
  align-items: center;
}
