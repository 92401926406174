.targetBox {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 999px;
}

.targetBox:hover {
  background: var(--hv-interaction-hover);
}
.targetBox:active {
  background: var(--hv-interaction-press);
}

.dropDown {
  display: flex;
  flex-direction: column;
  border-radius: var(--hv-radius-lg);
  border: 1px solid var(--hv-border-default) !important;
  background: var(--hv-surface-popup);
  padding: var(--hv-spacing-5);
  gap: var(--hv-spacing-2);
  box-shadow: 0px 16px 24px 0px var(--hv-shadow-4);
}

.profileContainer {
  padding: var(--hv-spacing-5) 0px;
  gap: var(--hv-spacing-4);
  align-items: center;
}

.titleEmailContainer {
  width: 100%;
  align-items: center;
  gap: var(--hv-spacing-1);
}

.item {
  padding: 0px var(--hv-spacing-2);
  gap: var(--hv-spacing-2);
  height: 40px !important;
  color: var(--hv-text-normal-secondary);
  stroke: var(--hv-text-normal-secondary);
}
.item:hover {
  color: var(--hv-text-normal-primary);
  stroke: var(--hv-text-normal-primary);
  background: var(--hv-interaction-hover);
}
.item:active {
  background: var(--hv-interaction-press);
}

.item .itemSection {
  margin-inline: 0px;
  size: 24px;
  background: inherit;
}

.item .itemSection svg {
  color: inherit;
  stroke: inherit;
}
