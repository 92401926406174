.root {
  height: 32px;
  border-radius: var(--mantine-radius-xs);
}

.root svg {
  stroke: inherit;
}
/*** Primary Button ***/

.Primary {
  background: var(--hv-button-primary-default);
  color: var(--hv-text-on-brand-primary);
  stroke: var(--hv-text-on-brand-primary);
}

.Primary:hover {
    background: var(--hv-button-primary-hover);
  }

.Primary:active {
    background: var(--hv-button-primary-press);
  }

.Primary:disabled {
    background: var(--hv-button-primary-disabled);
    color: var(--hv-text-on-brand-disabled);
    stroke: var(--hv-text-on-brand-disabled);
  }

/*** Secondary Btn ***/

.Secondary {
  background: var(--hv-button-secondary-default);
  color: var(--hv-text-normal-secondary);
  stroke: var(--hv-text-normal-secondary);
}

.Secondary:hover {
    background: var(--hv-button-secondary-hover);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Secondary:active {
    background: var(--hv-button-secondary-press);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Secondary:disabled {
    background: var(--hv-button-secondary-disabled);
    color: var(--hv-text-normal-disabled);
    stroke: var(--hv-text-normal-disabled);
  }

/*** Tertiary Button ***/

.Tertiary {
  background: transparent;
  color: var(--hv-text-normal-secondary);
  stroke: var(--hv-text-normal-secondary);
  border: 1px solid var(--hv-button-tertiary-outline-default);
}

.Tertiary:hover {
    background: var(--hv-button-tertiary-hover);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Tertiary:active {
    background: var(--hv-button-tertiary-press);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Tertiary:disabled {
    color: var(--hv-text-normal-disabled);
    stroke: var(--hv-text-normal-disabled);
  }

/*** Ghost Button ***/

.Ghost {
  background: transparent;
  color: var(--hv-text-normal-secondary);
  stroke: var(--hv-text-normal-secondary);
}

.Ghost:hover {
    background: var(--hv-button-ghost-hover);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Ghost:active {
    background: var(--hv-button-ghost-press);
    color: var(--hv-text-normal-primary);
    stroke: var(--hv-text-normal-primary);
  }

.Ghost:disabled {
    color: var(--hv-text-normal-disabled);
    stroke: var(--hv-text-normal-disabled);
  }
