.mainNavigation {
  width: 64px;
  padding: 2px 8px;
  gap: 16px;
}
.logoHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.logoHeader img {
  width: 48px;
  height: 48px;
}
.menuItem {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  width: 48px;
  height: 48px;
  padding: 4px;
  gap: 10px;
}

.menuItem:hover {
  cursor: pointer;
}

.menuIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.menuIcon:hover {
    padding: 8px 0px;
    border-radius: 8px;
    background: var(--hv-interaction-hover);
  }
.menuIcon.active {
  border-radius: 8px;
  background: var(--hv-interaction-press);
}
.menuIcon.active svg {
  fill: var(--hv-icon-normal-secondary);
  stroke: var(--hv-icon-on-brand-primary) !important;
}
