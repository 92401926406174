.flex:hover {
  cursor: pointer;
}

.text {
  font-weight: 500 !important;
  color: var(--hv-text-normal-secondary) !important;
}

.text:hover {
  color: var(--hv-text-normal-primary) !important;
}

.text:active {
  color: var(--hv-text-normal-primary) !important;
  font-weight: 600 !important;
}

.textActive {
  color: var(--hv-text-normal-primary) !important;
  font-weight: 600 !important;
}
