.root {
  background: var(--hv-snack-bar-background-default);
  border-radius: var(--hv-radius-sm);
  box-shadow: 0px 16px 24px 0px var(--hv-shadow-4);
  padding: var(--hv-spacing-5);
  align-items: flex-start;
  --notification-color: transparent !important;
  width: 400px;
  min-height: 20px;
}
div:has(div.root):where([data-position="top-right"]) {
  top: 68px;
  right: 0px;
  width: 420px;
}
.closeButton {
  color: var(--hv-snack-bar-text-default);
  background: transparent;
  min-height: 20px !important;
  height: 20px !important;
  --cb-icon-size: 20px !important;
  align-items: flex-start;
}

.closeButton:hover {
  background: var(--hv-snack-bar-icon-hover);
}

.icon {
  background: transparent;
  size: 20px;
  height: 20px;
  margin-inline-end: 8px;
}
