.topBar {
  flex-direction: row !important;
  height: 52px;
  justify-content: space-between;
  padding: 0px var(--mantine-spacing-2);
}
.leftComponent {
  flex-direction: row !important;
  gap: 20px;
  align-items: center;
}
.leftComponent svg {
  fill: var(--hv-text-normal-primary);
}
.leftComponentBredCrumbs svg {
  fill: none;
}

.leftComponentDivider {
  width: 1px;
  height: 16px;
  background: var(--hv-border-emphasis);
}

.rightComponent {
  flex-direction: row !important;
  gap: var(--mantine-spacing-2);
  align-items: center;
}
.rightComponent svg {
  stroke: var(--hv-text-normal-primary);
}

.rightComponentMenu {
  flex-direction: row !important;
  align-items: center;
}

.rightComponentItem {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 999px;
}

.rightComponentItem:hover {
    background: var(--hv-interaction-hover);
  }

.rightComponentItem:active {
    background: var(--hv-interaction-press);
  }
