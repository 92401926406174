.root {
  /* --accordion-radius: 12px; */
  min-width: 230px;
  border: none;
}
.item {
  border: none;
  border-bottom: 1px solid #e2e8ec;
}
[data-mantine-color-scheme='dark'] .item {
  border-bottom: 1px solid #353739;
}
.item {
  color: var(--mantine-color-mono-1);
}
[data-mantine-color-scheme='dark'] .item {
  color: #fff;
}
.item {
  background: var(--mantine-color-accordionLight);
}
[data-mantine-color-scheme='dark'] .item {
  background: var(--mantine-color-accordionDark);
}
.item {
  font-size: var(--mantine-font-size-md);
}
.item[data-active] {
    background-color: transparent;
  }
.item:last-child {
  border-bottom: 0px;
}
.control {
  border-radius: 12px;
}
.control:hover {
  color: var(--mantine-color-primary) !important;
  font-weight: 600 !important;
  background-color: var(--mantine-color-gray-2);
}
[data-mantine-color-scheme='dark'] .control:hover {
  background-color: #612e0d;
}
.control:hover[data-active] {
    background-color: #fddbba !important;
  }
[data-mantine-color-scheme='dark'] .control:hover[data-active] {
    background-color: #612e0d !important;
}
.label {
  padding: 10px;
  color: var(--mantine-color-mono-4);
}
[data-mantine-color-scheme='dark'] .label {
  color: var(--mantine-color-white);
}
.label {
  font-size: var(--mantine-font-size-md);
  font-weight: 600;
}
.icon {
  color: var(--mantine-color-mono-1);
}
[data-mantine-color-scheme='dark'] .icon {
  color: #fff;
}
.control {
  stroke: var(--mantine-color-mono-4);
}
[data-mantine-color-scheme='dark'] .control {
  stroke: var(--mantine-color-white);
}
.control {
  fill: var(--mantine-color-mono-4);
}
[data-mantine-color-scheme='dark'] .control {
  fill: var(--mantine-color-white);
}
