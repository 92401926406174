button {
  border: none;
  cursor: pointer;
}

.date-range-picker {
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-right: 18px;
  color: var(--hv-text-normal-primary);
  background-color: var(--hv-button-secondary-default);
  position: relative;
  border-radius: 3px;
  z-index: 500;
  height: 24px;
  padding: 0 8px;
  cursor: pointer;
}

.date-range-picker:not(.no-select):hover {
  background-color: var(--hv-button-secondary-hover);
}

.date-range-popup {
  width: fit-content;
  border: 1px solid var(--hv-surface-popup);
  box-shadow: var(--hv-shadow-4);
  background-color: var(--hv-surface-popup);
  border-radius: 16px;

  position: absolute;
  top: 28px;
  right: 0;
}

.date-range-popup > div:first-child {
  display: flex;
}

/* =============================== 
  date-range-picker-control
=============================== */

.date-range-picker-control {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4px;
}

.date-range-picker-control > div {
  display: flex;
  align-items: center;
}

.circle {
  width: 6px;
  height: 6px;
  /* dmpro */
  /* background-color: $ {({ theme }) => theme.color.systemRed}; */
  /* sightmind */
  background-color: var(--hv-data-red);
  border-radius: 50%;
  margin-right: 4px;
}

.mode {
  margin: 0;
  padding: 0;
  padding-right: 8px;
  margin-right: 8px;
  /* dmpro */
  /* border-right: 1px solid $ {({ theme }) => theme.color.mono9}; */
  /* sightmind */
  border-right: 1px solid var(--hv-border-default);
}

/* =============================== 
  dateRangePopupSidebar
=============================== */

.date-range-popup-sidebar {
  width: 180px;
  border-radius: 16px 0 0 16px;
  font-size: 14px;
  line-height: 18px;
  padding-top: 12px;
  background-color: var(--hv-surface-popup-panel);
}

.scrollbar-content {
  margin-bottom: -15px !important;
}

.date-range-popup-sidebar button.active {
  color: var(--hv-icon-normal-primary) !important;
  background-color: var(--hv-interaction-select-gray);
  border-color: var(--hv-brand-primary);
  border-radius: 0;
  opacity: 0.8;
}

.scrollbar-content::-webkit-scrollbar {
  width: 0;
}

/* =============================== 
  icon-calendar
=============================== */

.icon-calendar {
  display: flex;
  align-items: center;
}

.icon-calendar svg path {
  fill: var(--hv-icon-normal-tertiary);
}

.disable-preview-mode {
  width: fit-content;
  height: fit-content;
  background: none !important;
  padding: 0;
}

.disable-preview-mode > div {
  position: relative;
  top: 0px;
}

.disable-preview-mode .date-range-picker-control {
  display: none;
}

/* =============================== 
  date-range-calendar
=============================== */
.date-range-calendar {
  display: flex;
  flex-direction: column;
  background-color: var(--hv-surface-popup);
  border-radius: 16px !important;
}

/* =============================== 
  buttonSubmit
=============================== */

.button-submit {
  margin-left: auto;
  padding: 20px;
  background-color: var(--hv-surface-popup);
  border-radius: 0px 0px 16px 0px;
}

.button-submit button {
  min-width: 80px;
}

.button-submit button p {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-style: inherit;
  font-weight: inherit;
}
