.mantinePaper {
  /* @mixin light {
    --mrt-row-hover-background-color: var(--hv-orange-tertiary);
  }
  @mixin dark {
    --mrt-row-hover-background-color: var(--hv-orange-secondary);
  }
  --mrt-inner-width: 0px, background-color: light-dark(var(--hv-surface-widget)); */
  display: flex;
  flex-direction: column;
  /* border-width: 1px; */
  border-radius: 8px;
  box-shadow: none;
  border-color: var(--hv-border-default);
  height: 100vh;
}
.tableContainer {
  height: inherit;
  /* background-color: light-dark(white, var(--hv-surface-surface)); */
}
.headRow {
  /* max-height: 36px; */
  /* padding: 0px 20px; */
  /* background-color: light-dark(var(--hv-mono-9), var(--hv-mono-7)); */
  background-color: var(--hv-table-header-default);
  min-height: 36px;
  border-bottom: 1px solid var(--hv-border-default);
  /* &:hover {
    background-color: light-dark(var(--hv-mono-10), var(--hv-mono-6));
  }
  &[data-selected] {
    background-color: light-dark(var(--hv-mono-8));
  } */
}
.headCell {
  display: grid;
  background-color: transparent;
  padding: 0px var(--mantine-spacing-4);
  gap: 2px;
  font-weight: 600;
  font-size: var(--mantine-font-size-smBody);
  line-height: var(--mantine-line-height-smBody);
  letter-spacing: 0%;
  color: var(--hv-text-normal-secondary);
}
.headCell:hover {
    background-color: var(--hv-interaction-hover);
    color: var(--hv-text-normal-primary);
  }
.headCell:active {
    background-color: var(--hv-interaction-press);
    color: var(--hv-text-normal-primary);
  }
.headCell:where([kind="mrt-row-select"]) {
  padding: 0px var(--mantine-spacing-3);
}
.headCell:where([kind="mrt-row-expand"]) {
  padding: 0px var(--mantine-spacing-3);
}
.headCell:where([kind="mrt-row-expand"]) button {
  color: var(--hv-icon-normal-secondary) !important;
}
.headCell button {
  width: 20px;
  height: 20px;
  opacity: 1 !important;
}
.bodyRow {
  /* background-color: light-dark(white, var(--hv-surface-surface)); */
  /* padding: 0px 20px; */
  /* background-color: var(--hv-table-header-default); */
  min-height: 48px;
  border-bottom: 1px solid var(--hv-border-default);
}
/* border-bottom: 0.5px solid
    light-dark(var(--hv-border-default), var(--hv-border-light)) !important; */
.bodyRow:hover {
    background-color: var(--hv-interaction-hover);
  }
.bodyRow:active {
    background-color: var(--hv-interaction-press);
  }
.bodyRow[data-selected] {
    background-color: var(--hv-interaction-press);
  }
.bodyRow[data-selected]::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: var(--hv-brand-primary);
    }

.detailPanel:hover {
    background-color: var(--hv-interaction-hover);
  }

.detailPanel:active {
    background-color: var(--hv-interaction-press);
  }
.detailPanelSelected::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: var(--hv-brand-primary);
  }

.bodyCell {
  background: transparent !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  align-content: center;
  padding: 0px var(--mantine-spacing-4);
  gap: 10px;
}
.bodyCell:where([kind="mrt-row-select"]) {
  display: flex;
  padding: 0px var(--mantine-spacing-3);
  justify-content: center;
  margin-left: -1px !important;
}
.bodyCell:where([kind="mrt-row-expand"]) {
  display: flex;
  padding: 0px var(--mantine-spacing-3);
  justify-content: center;
  margin-left: -1px !important;
}
.bodyCell:where([kind="mrt-row-expand"]) button {
  color: var(--hv-icon-normal-secondary) !important;
}
