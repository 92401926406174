.root {
  color: var(--hv-text-normal-primary);
  letter-spacing: "0%";
}
.root:disabled {
  color: var(--hv-text-normal-disabled);
  letter-spacing: "0%";
}
.body-xs {
  font-size: var(--hv-fontsize-body-xs);
  line-height: var(--hv-lineheight-body-xs);
}
.body-sm {
  font-size: var(--hv-fontsize-body-sm);
  line-height: var(--hv-lineheight-body-sm);
}
.body-md {
  font-size: var(--hv-fontsize-body-md);
  line-height: var(--hv-lineheight-body-md);
}
.body-lg {
  font-size: var(--hv-fontsize-body-lg);
  line-height: var(--hv-lineheight-body-lg);
}
.headline-2xs {
  font-size: var(--hv-fontsize-headline-2xs);
  line-height: var(--hv-lineheight-headline-2xs);
}
.headline-xs {
  font-size: var(--hv-fontsize-headline-xs);
  line-height: var(--hv-lineheight-headline-xs);
}
.headline-sm {
  font-size: var(--hv-fontsize-headline-sm);
  line-height: var(--hv-lineheight-headline-sm);
}
.headline-md {
  font-size: var(--hv-fontsize-headline-md);
  line-height: var(--hv-lineheight-headline-md);
}
.headline-lg {
  font-size: var(--hv-fontsize-headline-lg);
  line-height: var(--hv-lineheight-headline-lg);
}
.headline-xl {
  font-size: var(--hv-fontsize-headline-xl);
  line-height: var(--hv-lineheight-headline-xl);
}
