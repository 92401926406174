.appsMenuContainer {
  align-content: flex-start;
  width: 360px;
  border: 1px solid var(--hv-border-default);
  border-radius: 16px;
  background-color: var(--hv-surface-popup);
  box-shadow: 0px 16px 24px var(--hv-shadow-4);
}
.logoContainer {
  display: grid;
  padding: var(--hv-spacing-6);
  grid-template-columns: 104px 104px 104px;
}
.helpContainer {
  width: 100%;
  border-top: 1px solid var(--hv-border-default);
  padding: var(--hv-spacing-4);
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.rightIcon {
  margin-left: auto;
}
