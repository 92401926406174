.rootWrapper {
  width: 200px;
  border-radius: 4px;
}
.rootWrapper svg {
  height: 20px;
  stroke: var(--hv-icon-normal-primary);
}
.dropdownContainer {
  gap: var(--mantine-spacing-2);
  padding-bottom: unset;
  padding-top: unset;
  background-color: var(--hv-input-foreground-default);
  border: 1px solid var(--hv-input-outline-default);
  color: var(--hv-text-normal-secondary);
}
.dropdownContainer:hover {
    background-color: var(--hv-input-foreground-hover);
    border: 1px solid var(--hv-input-outline-hover);
    color: var(--hv-text-normal-tertiary);
  }
.sectionContainer {
  height: inherit;
}
.optionsContainer {
  background-color: var(--hv-options-backgound-color);
  border: 1px solid var(--hv-options-border-color);
  box-shadow: 0 4 8 0 var(--hv-shadow-2);
  color: var(--hv-text-normal-primary);
  border-radius: 4px;
  max-height: 240px;
  gap: 10px;
}
.optionContainer {
  padding: var(--combobox-option-padding);
  border-radius: 4px;
  gap: xs;
  flex: 1;
}
.optionContainer:hover {
    background-color: var(--hv-interaction-hover);
  }
.optionContainer svg {
  height: 20px;
  stroke: var(--hv-icon-normal-primary);
}
.withIcon {
  padding-left: 36px;
}
.option {
  padding: unset !important;
  border-radius: 4px;
}
.selected {
  background-color: var(--hv-interaction-select-orange);
}
