.root {
  padding: 18px 20px;
  border-radius: 8px;
  box-shadow: 0px 16px 24px 0px var(--hv-shadow-4);
  --alert-bg: var(--hv-alert-background-default) !important;
  --alert-color: var(--hv-alert-text-default) !important;
}

.body {
  --alert-color: var(--hv-alert-text-default) !important;
}

.message {
  font-size: var(--mantine-font-size-smBody);
  line-height: var(--mantine-line-height-smBody);
  color: var(--hv-alert-text-default) !important;
}

.closeButton svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--hv-alert-icon-default) !important;
}
