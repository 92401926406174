.scrollbar[data-orientation="vertical"] {
  width: 12px;
}

.scrollbar[data-orientation="horizontal"] {
  height: 12px;
}

.scrollbar[data-orientation="vertical"] .thumb {
  background-color: var(--hv-scroll-default);
  width: 6px !important;
}

.scrollbar[data-orientation="horizontal"] .thumb {
  background-color: var(--hv-scroll-default);
  height: 6px !important;
}
