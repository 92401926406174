.scrollbar {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.scrollbar-content {
  margin-bottom: -15px !important;
}

.scrollbar-content > button {
  cursor: pointer;
  color: var(--hv-text-normal-secondary);
  width: 180px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-left: 2px solid transparent;
  background-color: transparent;
}

.scrollbar-content > button:hover {
  border-radius: 0;
  background-color: var(--hv-interaction-select-gray);
  color: var(--hv-text-normal-primary);
}

.scrollbar-content::-webkit-scrollbar {
  width: 0;
}
